<template>
  <div id="MultiImageFileUpload">
    <b-field label="Content Photos">
      <p v-if="type === 'product'" style="color: #676767;">
        Aspect Ratio 1:1 ( recommended 1024x1024px ) max size: 2048px
      </p>
      <p v-else style="color: #676767;">
        Aspect Ratio 16:9 ( recommended 1080x1920px ) max size: 2048px
      </p>
    </b-field>

    <b-field>
      <b-upload v-model="dropFiles" multiple drag-drop accept="image/*">
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"> </b-icon>
            </p>
            <p>Drop your files here or click to upload</p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <b-field
      label="Existing Images"
      v-if="existingContentUrls && existingContentUrls.length > 0"
    >
      <div class="columns is-multiline is-mobile">
        <div
          v-for="(url, index) in existingContentUrls"
          :key="index"
          class="column is-3-desktop is-4-tablet is-4-mobile"
        >
          <figure class="image is-256x256">
            <img class="cover-center" :src="url" />
          </figure>
          <b-button
            expanded
            outlined
            type="is-danger"
            icon-left="close"
            style="margin-top:0.5rem;"
            @click.prevent="presentConfirmRemoveExistingImage(url)"
          >
            Remove
          </b-button>
        </div>
      </div>
    </b-field>

    <b-field
      v-if="uploadedImages && uploadedImages.length > 0"
      label="New Images"
    >
      <div class="columns is-multiline is-mobile">
        <div
          v-for="(img_url, index) in uploadedImages"
          :key="index"
          class="column is-3-desktop is-4-tablet is-4-mobile"
        >
          <figure class="image is-128x128">
            <img class="cover-center" :src="img_url" />
          </figure>
          <b-button
            expanded
            outlined
            type="is-danger"
            icon-left="close"
            style="margin-top:0.5rem;"
            @click.prevent="deleteUploadedImage(index)"
          >
            Remove
          </b-button>
        </div>
      </div>
    </b-field>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";

export default {
  name: "MultiImageFileUpload",
  props: ["type", "existingContentUrls"],
  data() {
    return {
      dropFiles: [],
      displayImgs: [],
      uploadedImages: [],
      inprogressList: []
    };
  },
  watch: {
    dropFiles: function(val) {
      if (val) {
        this.displayFiles(val);
      }
    }
  },
  methods: {
    displayFiles: function(files) {
      files.forEach(element => {
        const file_name = element.name;
        if (!this.inprogressList.includes(file_name)) {
          this.inprogressList.push(file_name);
          this.uploadImage(element);
        }
      });
    },
    uploadImage: async function(file) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const uploadResponse = await Helpers.uploadImage(file);
      loadingComponent.close();

      switch (uploadResponse.code) {
        case "0":
          // Append new upkload url
          this.uploadedImages.push(uploadResponse.data.urls[0]);

          // Remove uploaded file from drop file list
          // eslint-disable-next-line
          const index = this.dropFiles.findIndex(
            drop_file => drop_file.name === file.name
          );
          this.dropFiles.splice(index, 1);
          // Remove uploaded file from drop file list
          // eslint-disable-next-line
           const inprogress_index = this.inprogressList.findIndex(
            item => item === file.name
          );
          this.inprogressList.push(inprogress_index, 1);

          // Emit event to parent component
          this.$emit("uploaded_files", this.uploadedImages);
          break;

        default:
          this.$buefy.snackbar.open({
            message: uploadResponse.code + " Error: " + uploadResponse.message,
            type: "is-danger"
          });
          break;
      }
    },
    deleteUploadedImage: async function(index) {
      const remove_url = this.uploadedImages[index];
      const remove_file_name = Helpers.convertUrlToImageName(remove_url);

      // Start requesting deleting file
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      await Helpers.removeImage(remove_file_name);
      loadingComponent.close();

      // Splice remove_url from uploaded image list
      this.uploadedImages.splice(index, 1);

      // Emit event to parent component
      this.$emit("uploaded_files", this.uploadedImages);
    },
    deleteExistingImage: async function(remove_url) {
      const remove_index = this.existingContentUrls.findIndex(
        url => url === remove_url
      );
      const remove_file_name = Helpers.convertUrlToImageName(remove_url);

      // Start requesting deleting file
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      await Helpers.removeImage(remove_file_name);
      loadingComponent.close();

      // Splice remove_url from existing image list
      this.existingContentUrls.splice(remove_index, 1);
    },
    presentConfirmRemoveExistingImage: function(existing_url) {
      const file_name = Helpers.convertUrlToImageName(existing_url);
      if (file_name) {
        this.$buefy.dialog.confirm({
          title: "Confirm Remove Existing Image",
          message:
            "Are you sure you want to permanently remove <b>" +
            file_name +
            "</b>?",
          confirmText: "Confirm",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteExistingImage(existing_url)
        });
      }
    }
  }
};
</script>

<style scoped>
.cover-center {
  object-position: center;
  object-fit: cover;
  height: 128px;
}
</style>
